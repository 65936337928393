import { useCallback, useContext, useMemo, useState } from 'react'
import { useAnalyticsEvents, useAnalyticsPropValues } from 'lib/data/use-analytics'
import { useMetrics } from 'lib/data/use-metrics'
import { SystemLabels } from 'lib/metrics/labels'
import { any2string } from 'lib/any'
import { QueryRangeContext } from '../../lib/context/query-range-context'

type Option = { value: string; label: string }

export function useTmplVarValues(projectId?: string, propKey?: string, importedProjectId?: string) {
  const { isShare, shareId, shareProject } = useContext(QueryRangeContext) || {}
  const fromShare = isShare ? { shareId, project: shareProject } : undefined
  const fromExternal = importedProjectId ? { projectId, externalProjectId: importedProjectId } : undefined
  const { metrics } = useMetrics(projectId, fromShare, fromExternal)
  const { events } = useAnalyticsEvents(projectId, fromShare, fromExternal)

  const [search, setSearch] = useState<string>('')
  const { values } = useAnalyticsPropValues(projectId, '', propKey ? [propKey] : [], search, fromShare, fromExternal)

  const options: Option[] = useMemo(() => {
    const options: Record<string, string> = {}
    for (const metric of metrics || []) {
      for (const label in metric.labels) {
        if (label == 'name') {
          continue
        }
        if (label == propKey) {
          metric.labels[label]?.values?.forEach((v) => (options[v] = v))
        }
      }
      for (const sl of SystemLabels) {
        if (sl.name == propKey) {
          sl.getValues(metric).forEach(({ value, display }) => {
            options[value] = display
          })
        }
      }
    }
    values?.forEach((v) => {
      const value = any2string(v.value)
      options[value] = options[value] ?? value
    })

    return Object.entries(options)
      .map(([value, label]) => ({ value, label }))
      .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }))
  }, [metrics, values, propKey])

  const labels = useMemo(() => {
    const labels = new Set<string>()
    for (const metric of metrics || []) {
      for (const label in metric.labels) {
        if (label == 'name') {
          continue
        }
        labels.add(label)
      }
      for (const sl of SystemLabels) {
        labels.add(sl.name)
      }
    }
    for (const event of events || []) {
      for (const prop of event.properties || []) {
        labels.add(prop.name!)
      }
    }
    return Array.from(labels).sort()
  }, [events, metrics])

  const filterFn = useCallback(
    (options: Option[], input: string) => {
      if (input != search) {
        setSearch(input)
      }

      return options.filter((o) => o.label.toLowerCase().includes(input.toLowerCase()))
    },
    [options, search, setSearch]
  )

  return {
    labels,
    options,
    search,
    setSearch,
    filterFn
  }
}
