import { Combobox } from '@headlessui/react'
import classNames from 'lib/classnames'
import { CheckIcon, XMarkIcon as XIcon } from '@heroicons/react/20/solid'
import { RiCheckFill } from 'react-icons/ri'
import { BiCaretDown } from 'react-icons/bi'
import { useMemo, useRef, useState, useCallback } from 'react'
import { CopyButton } from '../buttons/CopyButton'

export interface Props<T> {
  options: T[]
  value?: T
  onChange: (value?: T) => void
  error?: any
  display?: (option: T) => string | undefined
  className?: string
  inputClassName?: string
  placeholder?: string
  maxOptions?: number
  label?: string
  defaultValue?: T
  hideClearButton?: boolean
  filterFn?: (options: T[], term: string) => T[]

  input?: string
  onInputChange?: (input: string) => void
  supportCopy?: boolean
  optionClassName?: string
}

function defaultFilterFn<T>(options: T[], term: string, display?: (option: T) => string | undefined) {
  return options.filter((o) => (display ? display(o) : String(o))?.toLowerCase().includes(term?.toLowerCase()))
}

export function ComboSelect<T>({
  options,
  value,
  onChange,
  error,
  display,
  className,
  inputClassName,
  placeholder,
  maxOptions,
  label,
  defaultValue,
  filterFn,
  input: inputFromProps,
  onInputChange,
  supportCopy,
  optionClassName
}: Props<T>) {
  const isControlled = typeof inputFromProps != 'undefined'

  const [internalInput, setInternalInput] = useState<string>('')
  const input = isControlled ? inputFromProps : internalInput
  const setInput = (v) => {
    if (onInputChange) {
      onInputChange(v)
    }

    if (!isControlled) {
      setInternalInput(v)
    }
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const filteredOptions = useMemo(() => {
    const filtered = input ? (filterFn ? filterFn(options, input) : defaultFilterFn(options, input, display)) : options
    return maxOptions || 0 > 0 ? filtered.slice(0, maxOptions) : filtered
  }, [options, input, filterFn, maxOptions, display])

  const clearSelection = useCallback(
    function clearSelection(evt: React.MouseEvent<HTMLDivElement>) {
      evt.stopPropagation()
      onChange(undefined)
      setInput('')
    },
    [onChange]
  )

  return (
    <div className={'relative w-full'}>
      {label && (
        <label
          className="dark:bg-sentio-gray-100 absolute -top-2.5 left-2 bg-white px-0.5 text-[10px] text-gray-600"
          style={{ zIndex: 1 }}
        >
          {label}
        </label>
      )}
      <Combobox
        defaultValue={value || defaultValue}
        onChange={(value) => {
          onChange(value)
          setInput(undefined)
        }}
      >
        <div
          className={classNames(
            className,
            'hover:border-primary-500  group flex flex-grow border',
            inputClassName,
            'relative w-full items-center',
            error
              ? 'border-red-500 focus:border-red-500  focus:ring-red-500'
              : 'focus:border-primary-500 focus:ring-primary-500 border-border-color'
          )}
        >
          <Combobox.Button as="div" className="flex w-full">
            <Combobox.Input
              as="div"
              tabIndex={0}
              placeholder={placeholder}
              className={classNames(
                'text-icontent w-[calc(100%-6rem)] flex-grow border-0 py-1 pl-2 pr-0 ring-0 focus:outline-none focus:ring-0'
              )}
              onFocus={() => inputRef.current?.focus()}
              onChange={(event) => setInput(event.target.value)}
            >
              <div
                className={classNames(
                  value ? 'text-text-foreground' : 'text-gray-400',
                  'left-0 w-full cursor-pointer truncate'
                )}
              >
                {value
                  ? display
                    ? display(value)
                    : String(value)
                  : defaultValue
                    ? display
                      ? display(defaultValue)
                      : String(defaultValue)
                    : placeholder}
              </div>
              <div className="h-0 text-sm opacity-0">{label} </div>
            </Combobox.Input>
            {defaultValue == null && value ? (
              <div
                role={'button'}
                aria-label={'clear'}
                className={classNames('invisible inset-y-0 flex cursor-pointer items-center p-1 group-hover:visible')}
                onClick={clearSelection}
              >
                <XIcon
                  className="hover:bg-primary-400 mr-2 h-4 w-4 rounded-lg text-gray-400 hover:text-white"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <div
                role={'button'}
                aria-label={'clear'}
                className={classNames('inset-y-0 flex cursor-pointer items-center p-1')}
              >
                <BiCaretDown className="group-hover:text-gray mr-2 h-4 w-4 text-gray-400" aria-hidden="true" />
              </div>
            )}
          </Combobox.Button>

          <Combobox.Options
            as="div"
            className={classNames(
              'ring-primary-500 focus:ring-primary-500 divide-primary-500 dark:bg-sentio-gray-100 absolute z-10 min-w-full gap-0  divide-y rounded bg-white text-sm shadow-lg ring-1',
              label ? 'left-px top-[2px] -translate-x-px -translate-y-[2px] py-1' : 'left-0 top-0 py-0.5',
              'max-w-[80vw] sm:max-w-sm xl:max-w-md',
              optionClassName
            )}
          >
            <div>
              <label
                className="dark:bg-sentio-gray-100 absolute -top-[11px] left-[7px] bg-white px-0.5 text-[10px] text-gray-600"
                style={{ zIndex: 1 }}
              >
                {label}
              </label>
              <input
                type="text"
                ref={inputRef}
                className={classNames(
                  'text-icontent mb-0.5 h-6 w-full border-0 px-2 py-0 ring-0 focus:outline-none focus:ring-0'
                )}
                tabIndex={0}
                onChange={(e) => setInput(e.target.value)}
                value={input ?? ''}
                autoComplete="off"
              />
            </div>
            <ul className="max-h-60 overflow-auto pt-1">
              {filteredOptions.map((m, idx) => (
                <Combobox.Option
                  key={idx}
                  value={m}
                  className={({ active }) =>
                    classNames(
                      'text-icontent relative cursor-default select-none py-2 pl-2',
                      active ? 'bg-primary-50 dark:bg-primary-600 text-gray-800 dark:text-white' : 'text-gray',
                      supportCopy ? 'group/option pr-7' : 'pr-2'
                    )
                  }
                >
                  {({ active }) => {
                    const text = (display ? display(m) : String(m)) || '(empty)'
                    const selected = m === (value || defaultValue)
                    return (
                      <>
                        <span
                          title={text}
                          className={classNames('text-gray block truncate pl-4', selected && 'font-semibold')}
                        >
                          {text}
                          {supportCopy ? (
                            <span
                              className="invisible absolute right-1 top-2 group-hover/option:visible"
                              onClick={(evt) => {
                                evt.preventDefault()
                                evt.stopPropagation()
                              }}
                            >
                              <CopyButton
                                text={text}
                                iconClass="ml-1 h-5 w-5 bg-white dark:bg-sentio-gray-100 p-1 hover:text-primary hover:bg-gray-200 inline-block rounded-full"
                              />
                            </span>
                          ) : null}
                        </span>
                        <span
                          className={classNames(
                            'absolute inset-y-0 left-2 flex items-center pr-4',
                            active ? 'text-white' : 'text-primary-600'
                          )}
                        >
                          <span
                            className={classNames(
                              'inline-block min-h-[12px] min-w-[12px] rounded-sm border border-gray-300',
                              active ? 'dark:border-sentio-gray-800' : selected ? 'bg-primary' : ''
                            )}
                          >
                            {selected && <RiCheckFill className="h-3 w-3 text-white" aria-hidden="true" />}
                          </span>
                        </span>
                      </>
                    )
                  }}
                </Combobox.Option>
              ))}
            </ul>
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  )
}

ComboSelect.defaultProps = {
  maxOptions: 100,
  placeholder: '*'
}
