import { produce } from 'immer'
import { PlusIcon } from '@heroicons/react/24/outline'
import { XMarkIcon as XIcon } from '@heroicons/react/20/solid'
import Button from 'components/common/buttons/NewButton'
import { DisclosurePanel } from 'components/common/panel/DisclosurePanel'
import { ChartConfigMarker, ChartConfigMarkerType } from '@sentio/service/web'
import { MarkLine } from '../TimeSeriesChart'

export function chartConfigToMarkLines(markers: ChartConfigMarker[] = []): MarkLine[] {
  return markers
    .map((marker) => {
      if (marker.type === ChartConfigMarkerType.LINEX) {
        if (!marker.valueX) {
          return
        }
        return { from: marker.valueX, to: marker.valueX, color: marker.color, label: marker.label }
      }
      return marker
    })
    .filter(Boolean) as MarkLine[]
}

interface Props {
  markers?: ChartConfigMarker[]
  onChange: (v: ChartConfigMarker[]) => void
}

const labelCls =
  'inline-flex items-center border border-r-0 sm:text-icontent border-gray-300  bg-gray-50 px-2 rounded-l-md'
const inputCls = 'border focus:border-primary-500 rounded-r-md sm:text-icontent border-gray-300  w-28'

function MarkerInput({
  marker,
  onChange,
  onRemove
}: {
  marker: ChartConfigMarker
  onChange: (v: ChartConfigMarker) => void
  onRemove: () => void
}) {
  const _onChange = (field, value) => {
    onChange(
      produce(marker, (draft) => {
        draft[field] = value
      })
    )
  }
  return (
    <div className="mb-1 flex gap-[10px]">
      <label className="inline-flex h-8">
        <span className={labelCls}>
          <span className="pr-2">A</span>
          <select
            className="sm:text-ilabel inline-flex h-full items-center border border-b-0 border-t-0 border-gray-300  bg-gray-50 p-0 pl-4 pr-7 text-gray-500 focus:border-0 focus:ring-inset"
            value={marker.type}
            onChange={(e) => _onChange('type', e.target.value)}
          >
            <option value={ChartConfigMarkerType.LINE}>horizontal line</option>
            <option value={ChartConfigMarkerType.LINEX}>vertical line</option>
          </select>
          <span className="pl-2">at</span>
        </span>
        {marker.type === ChartConfigMarkerType.LINEX ? (
          <input
            className={inputCls}
            type="text"
            value={marker.valueX}
            placeholder="YYYY-MM-DD"
            onChange={(e) => _onChange('valueX', e.target.value)}
          />
        ) : (
          <input
            className={inputCls}
            type="text"
            value={marker.value}
            onChange={(e) => _onChange('value', e.target.value)}
          />
        )}
      </label>
      <label className="inline-flex h-8">
        <span className={labelCls}>Color</span>
        <div className="relative">
          <div className="absolute inset-0 flex w-8 items-center justify-center">
            <div className="h-4 w-4" style={{ background: marker.color }} />
          </div>
          <input
            className={inputCls + ' pl-8'}
            type="text"
            value={marker.color}
            onChange={(e) => _onChange('color', e.target.value)}
          />
        </div>
      </label>
      <label className="inline-flex h-8">
        <span className={labelCls}>Label</span>
        <input
          className={inputCls}
          type="text"
          value={marker.label}
          onChange={(e) => _onChange('label', e.target.value)}
        />
      </label>
      <button type="button" className="group ml-2 w-5" aria-label="Remove marker" onClick={onRemove}>
        <XIcon className={'h-5 w-5 text-gray-400 group-hover:text-gray-600'} aria-hidden="true" />
      </button>
    </div>
  )
}

const DEFAULT_MARKER = { value: 0, color: '#ff0000', label: '' }

export function MarkerControls({ markers, onChange }: Props) {
  const _markers = markers?.length ? markers : []

  const onAdd = () => {
    onChange(
      produce(_markers, (draft) => {
        draft.push(DEFAULT_MARKER)
      })
    )
  }

  const onRemove = (index) => {
    onChange(
      produce(_markers, (draft) => {
        draft.splice(index, 1)
      })
    )
  }

  const _onChange = (index, marker) => {
    onChange(
      produce(_markers, (draft) => {
        draft[index] = marker
      })
    )
  }

  return (
    <DisclosurePanel
      title="Markers"
      containerClassName="w-full rounded bg-white dark:bg-sentio-gray-100 border border-border-color"
      defaultOpen={true}
    >
      <div>
        {_markers.map((marker, index) => (
          <MarkerInput
            marker={marker}
            key={index}
            onChange={(v) => _onChange(index, v)}
            onRemove={() => onRemove(index)}
          />
        ))}
        <div>
          <Button type="button" className="mt-2" onClick={onAdd}>
            <PlusIcon className="h-4 w-4" aria-hidden="true" />
            Add Marker
          </Button>
        </div>
      </div>
    </DisclosurePanel>
  )
}
