import { RetentionSeries } from '../../lib/data/use-retention'
import { EChartsHandle, EChartsOption, ReactEChartsBase } from '../charts/EchartsBase'
import { CSSProperties, forwardRef, useCallback, useMemo } from 'react'
import { RetentionQueryInterval, RetentionQueryIntervalUnit } from '../../gen/service/common'
import { Chart } from 'gen/service/web'
import { Series } from '../../lib/metrics/series'
import classNames from '../../lib/classnames'
import { NumberFormat } from '../../lib/number'

interface Props {
  data?: Record<string, RetentionSeries>
  title?: string
  interval?: RetentionQueryInterval
  windowSize?: number
  group?: string
  tz?: string
  minHeight?: number
  loading?: boolean
  controls?: boolean
  style?: CSSProperties
  allowEdit?: boolean
  panelId?: string
  showSymbol?: boolean
  allowClick?: boolean
  getEventNameById?: (id?: string) => string
  noLegend?: boolean
  chartDataRef?: React.MutableRefObject<Chart>
}

const NF = NumberFormat({ notation: 'compact', maximumFractionDigits: 2 })
const PercentFormatter = Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 })

export const RetentionChart = forwardRef<EChartsHandle, Props>((props: Props, ref) => {
  const {
    data,
    title,
    windowSize = 7,
    interval = { unit: RetentionQueryIntervalUnit.Day, value: 1 },
    loading,
    group,
    minHeight,
    style
  } = props

  const { series, legends, timeslots } = useMemo(() => {
    const series: Series<number>[] = []
    const legends: string[] = []
    for (const [key, value] of Object.entries(data || {})) {
      legends.push(key)
      series.push({
        name: key,
        type: 'line',
        data: value.averageRow.rates.map((v, i) => [i, v]),
        showSymbol: false,
        id: key
      })
    }
    const timeslots: string[] = []
    for (let i = 1; i < windowSize; i++) {
      const n = i * (interval.value || 1)
      if (i == 1) {
        timeslots.push(`< ${interval.unit} ${n}`)
      }
      timeslots.push(`${interval.unit} ${n}`)
    }

    return { series, legends, timeslots }
  }, [data, interval, windowSize])

  const tooltipFormatter = useCallback(
    (params /*ticket, callback*/) => {
      params.sort((p1, p2) => p2.value[1] - p1.value[1])
      let timeslot = ''
      const rows = params.map((p) => {
        const { marker, seriesName, value, seriesId } = p
        const highlighted = seriesId === global.highlightSeriesId
        const d = data?.[seriesName]
        let rate: number | undefined
        let count: number | undefined
        if (d) {
          rate = d.averageRow.rates[value[0]]
          count = d.averageRow.counts[value[0]]
          timeslot = timeslots[value[0]]
        }

        const classname = classNames('text-gray-700', 'truncate', 'series-name')
        const containerClass = classNames(
          highlighted ? 'highlighted' : '',
          'max-w-full grid items-center tooltip-item px-2',
          `series_${seriesId}`
        )
        return `<div class="${containerClass}" style="grid-template-columns: min-content 1fr auto;">
${marker}
<span class="${classname}" style="min-width: 4rem;">${seriesName}</span>
<div class="flex flex-col">
<span class="truncate min-w-0 ml-4 text-right"><span class="font-semibold">${PercentFormatter.format(rate || 0)}</span>
retention
</span>
<span class="truncate min-w-0 ml-4 text-right">
<span class="font-semibold">${NF.format(count || 0)}</span> users (average)
</span>
</div>
</div>`
      })

      return (
        `<div class="">
        <span class="px-2 font-semibold">${timeslot} </span>
` +
        rows.join('') +
        `</div>`
      )
    },
    [data]
  )

  const options: EChartsOption = useMemo(
    () => ({
      title: {
        text: title
      },
      grid: {
        top: title ? 48 : 8,
        right: 32,
        bottom: 16,
        left: 16,
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLabel: {
          hideOverlap: true
        },
        data: timeslots
      },
      dataZoom: {
        type: 'inside',
        // startValue: selectStart,
        // endValue: selectEnd,
        zoomLock: true
      },
      legend: {
        data: legends,
        top: -10000,
        left: -10000
      },
      brush: undefined,
      toolbox: {
        show: false
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: function (value) {
            return PercentFormatter.format(value)
          }
        }
      },
      animation: false,
      series: series as any,
      tooltip: {
        trigger: 'axis',
        confine: true,
        extraCssText: 'max-width: 50%; max-height: 50vh; overflow-y: auto; padding: 10px 0;',
        formatter: tooltipFormatter
      }
    }),
    [title, legends, series, interval, tooltipFormatter]
  )

  return (
    <div className="h-full w-full">
      <ReactEChartsBase
        ref={ref}
        loading={loading}
        group={group}
        option={options}
        minHeight={minHeight}
        style={style}
      />
    </div>
  )
})

RetentionChart.displayName = 'RetentionChart'
