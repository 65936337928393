import { PencilIcon } from '@heroicons/react/24/outline'
import classNames from 'lib/classnames'
import { DashboardExtraTemplateVariable } from 'gen/service/web'
import { produce } from 'immer'
import { useTemplateValues } from 'lib/data/use-template-values'
import { ComboSelect as NewComboSelect } from '../common/select/NewComboSelect'
import { useTmplVarValues } from './use-tmplvar-data'
import { useMemo } from 'react'
import { useImportedSource } from './use-source-name'

interface Props {
  variables: { [p: string]: DashboardExtraTemplateVariable }
  className?: string
  onClickEdit?: () => void
  projectId?: string
  enableExternal?: boolean
}

export function TemplateVariableToolbar({ variables, className, onClickEdit, projectId, enableExternal }: Props) {
  const { templateValues, setTemplateValues } = useTemplateValues(variables)

  const onChangeValue = (field: string, v: string | null = null) => {
    setTemplateValues(
      produce(templateValues, (draft) => {
        draft[field] = v
      })
    )
  }

  return (
    <div
      className={classNames(
        'dark:bg-sentio-gray-100 border-border-color -mt-1 flex flex-wrap gap-2 border-b bg-white p-2 pl-4',
        className
      )}
    >
      {Object.keys(variables).map((name) => {
        const variable = variables[name]
        if (variable.sourceName == '__custom__') {
          return (
            <div className="w-fit" key={name}>
              <NewComboSelect
                className="border-border-color max-w-[80vw] rounded-md border p-[1px]"
                label={name}
                options={variable.options || []}
                value={templateValues[name]}
                onChange={(v) => onChangeValue(name, v)}
                supportCopy
                onInputChange={(v) => v != null && onChangeValue(name, v)}
              />
            </div>
          )
        }

        return (
          <TemplateVariableSelectWithSource
            projectId={projectId}
            key={name}
            name={name}
            field={variable.field}
            value={templateValues[name]}
            onChange={(v) => onChangeValue(name, v)}
            sourceName={enableExternal ? variable.sourceName : undefined}
          />
        )
      })}
      {onClickEdit && (
        <button
          onClick={onClickEdit}
          className="hover:bg-primary-700 rounded border p-1 text-gray-500 hover:text-white"
          aria-label="Edit template variables"
        >
          <PencilIcon className="h-4 w-4 " />
        </button>
      )}
    </div>
  )
}

interface SelectProps {
  name: string
  onChange: (v?: string) => void
  value?: string
  field?: string
  projectId?: string
  importedProjectId?: string
}

export function TemplateVariableSelect({ projectId, importedProjectId, name, onChange, value, field }: SelectProps) {
  const { options, filterFn, search, setSearch } = useTmplVarValues(projectId, field, importedProjectId)

  const { allOptions, currentValue } = useMemo(() => {
    let currentValue = options.find((p) => p.value == value)
    const allOptions = [...options]
    if (value && !currentValue) {
      currentValue = { label: value, value }
      allOptions.push(currentValue)
    }
    return { allOptions, currentValue }
  }, [options, value])

  return (
    <div className="w-fit">
      <NewComboSelect
        className="border-border-color max-w-[80vw] rounded-md border p-[1px]"
        label={name}
        options={allOptions}
        value={currentValue}
        onChange={(v) => onChange(v?.value)}
        display={(v) => v?.label}
        filterFn={filterFn}
        input={search}
        onInputChange={setSearch}
        supportCopy
      />
    </div>
  )
}

export function TemplateVariableSelectWithSource({
  projectId,
  name,
  onChange,
  value,
  field,
  sourceName
}: SelectProps & {
  sourceName?: string
}) {
  const sourceProjectId = useImportedSource(projectId, sourceName)

  return (
    <TemplateVariableSelect
      projectId={projectId}
      importedProjectId={sourceProjectId}
      key={name}
      name={name}
      field={field}
      value={value}
      onChange={onChange}
    />
  )
}
