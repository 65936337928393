import { dateTimeToString, DateTimeValue, durationToSeconds } from '../time'
import { InsightsService, RetentionRequest } from '../../gen/service/insights'
import { useProjectVersions } from './use-project-versions'
import { useProject } from './use-project'
import {
  Duration,
  Permission,
  Project,
  RetentionMatrixSample,
  RetentionQuery,
  RetentionQueryIntervalUnit
} from '../../gen/service/common'
import { useMemo } from 'react'
import useApi from './use-api'
import dayjs from 'dayjs'
import { maxBy } from 'lodash'

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

export function useRetentionQuery(
  projectId: string | undefined | null,
  startTime: DateTimeValue,
  endTime: DateTimeValue,
  step: Duration,
  query: RetentionQuery,
  refreshInterval = 10000,
  fromShare?: { shareId: string; panelId: string; project?: Project }
) {
  const { currentVersion } = useProjectVersions()
  const { owner, slug } = useProject([Permission.READ])

  // const resolvedQueries =  queries // todo: support variables   resolveVariables(queries, variablesValues)
  const req: RetentionRequest = {
    projectOwner: fromShare?.project?.ownerName ?? (owner as string),
    projectSlug: fromShare?.project?.slug ?? (slug as string),
    version: currentVersion || undefined,
    timeRange: {
      start: dateTimeToString(startTime),
      end: dateTimeToString(endTime),
      step: durationToSeconds(step),
      timezone: tz
    },
    query
  }

  const isReady = useMemo(() => {
    if (projectId) {
      const resources = req?.query?.resources
      return resources && resources.length >= 2
    }

    return false
  }, [req, projectId])
  const {
    data: response,
    loading,
    ready,
    error,
    mutate
  } = useApi(InsightsService.Retention, isReady ? req : null, false, {
    refreshInterval,
    fromShare
  })

  const data = useMemo(() => {
    if (response) {
      const series =
        response?.results?.samples?.reduce(
          (acc, sample) => {
            const seriesName = toSeriesName(sample?.labels || {})
            const seriesData = acc[seriesName] || {
              labels: sample.labels,
              total: 0,
              samples: []
            }
            seriesData.samples.push(sample)
            seriesData.rowsByDate = seriesData.rowsByDate || {}
            seriesData.rowsByDate[sample.time!] = {
              total: sample.totalCount || 0,
              counts: sample.counts!,
              rates: sample.rates!,
              labels: sample.labels || {},
              time: sample.time
            }
            acc[seriesName] = seriesData
            return acc
          },
          {} as Record<string, RetentionSeries>
        ) || {}

      const intervalUnit = query.interval?.unit || RetentionQueryIntervalUnit.Day

      for (const s of Object.values(series)) {
        const rowLength = maxBy(s.samples, (s) => s?.counts?.length || 0)?.counts?.length || 0
        s.averageRow = {
          counts: Array(rowLength).fill(0),
          rates: Array(rowLength).fill(0),
          total: 0,
          labels: s.labels
        }
        for (let i = 0; i < rowLength; i++) {
          const completedRows = Object.entries(s.rowsByDate)
            .filter(([time, row]) => {
              const diff = dayjs().diff(dayjs(time), intervalUnit.toLowerCase() as 'day' | 'month' | 'week')
              return diff >= i
            })
            .map(([_, row]) => row)
          const total = completedRows.reduce((acc, row) => acc + row.total, 0)
          s.averageRow.counts[i] = completedRows.reduce((acc, row) => {
            const weight = row.total / total
            return acc + row.counts[i] * weight
          }, 0)
          s.averageRow.total = completedRows.reduce((acc, row) => {
            const weight = row.total / total
            return acc + row.total * weight
          }, 0)
          s.averageRow.rates[i] = s.averageRow.counts[i] / s.averageRow.total
        }
      }
      return series
    }
  }, [response, query])

  return { data, error, loading, ready, mutate, payload: req }
}

export interface RetentionRow {
  total: number
  counts: number[]
  rates: number[]
  time?: string
  labels: Record<string, string>
}

export interface RetentionSeries {
  total: number
  samples: RetentionMatrixSample[]
  rowsByDate: Record<string, RetentionRow>
  averageRow: RetentionRow
  labels: Record<string, string>
}

export function toSeriesName(labels: { [p: string]: string }) {
  const keys = Object.keys(labels).sort()
  if (keys.length === 0) {
    return 'Overall'
  }
  return keys.map((k) => `${k}=${labels[k]}`).join(' / ')
}
