import { useContext, useMemo } from 'react'
import { ProjectContext } from 'lib/dash/context'
import useApi from 'lib/data/use-api'
import { WebService } from '@sentio/service/web'

export const useImportedSource = (_projectId?: string, sourceName?: string) => {
  const { owner, slug } = useContext(ProjectContext)
  const { data: importedProjects } = useApi(
    WebService.GetImportedProject,
    owner && slug && sourceName
      ? {
          ownerName: owner,
          slug
        }
      : null
  )
  return useMemo(() => {
    if (sourceName) {
      return importedProjects?.imports?.find((p) => p.name == sourceName)?.imported?.id
    } else {
      return _projectId
    }
  }, [_projectId, sourceName, importedProjects])
}
