import { useQueryRange } from './use-query-range'
import { ChartDataSourceType, Panel } from 'gen/service/web'
import { useContext, useMemo } from 'react'
import { QueryRangeContext } from '../context/query-range-context'
import { useQuerySegmentation } from './use-analytics'
import { DateTimeValue, durationToSeconds, toDayjs } from '../time'
import { QuerySegmentationResponse } from 'gen/service/analytic'
import { KeyedMutator } from 'swr'
import { MetricsQueryResponse } from 'gen/service/observability'
import { QueryRangeRequest } from '../../gen/service/observability'
import { SegmentationRequest, Source, SyncExecuteSQLResponse } from '../../gen/service/analytic'
import { useQueryInsights } from './use-insights'
import { useTableLimit } from './use-table-state'
import { calculateStep } from '../interval'
import { Chart } from '../../gen/service/web'
import { RetentionSeries, useRetentionQuery } from './use-retention'
import { useSQLQuery } from './use-sql'

export type PanelQueryParams = {
  enabled?: boolean
  projectId?: string
  panel: Panel
  startTime: DateTimeValue
  endTime: DateTimeValue
  tz: string
  refreshInterval?: number
  templateValues: Record<string, never>
}

export type PanelQueryResponse = {
  data?: QuerySegmentationResponse | MetricsQueryResponse | Record<string, RetentionSeries> | SyncExecuteSQLResponse
  compareData?:
    | QuerySegmentationResponse
    | MetricsQueryResponse
    | Record<string, RetentionSeries>
    | SyncExecuteSQLResponse
  error?: any
  loading?: boolean
  ready?: boolean
  mutate?: KeyedMutator<any>
  bypassCacheMutate?: () => Promise<any>
  isQueryRange?: boolean
  isQuerySegmentation?: boolean
  isShareDashboard?: boolean
  isInsights?: boolean
  payload?: QueryRangeRequest | SegmentationRequest
  apiUrl?: string
}

export function usePanelQueryData({
  panel,
  startTime,
  endTime,
  tz,
  refreshInterval,
  templateValues,
  projectId,
  enabled
}: PanelQueryParams): PanelQueryResponse {
  const chart = panel.chart
  const { shareId, isShare, shareProject } = useContext(QueryRangeContext) || {}
  const fromShare = isShare && shareId ? { shareId, panelId: panel.id!, project: shareProject } : undefined
  projectId = projectId ?? fromShare?.project?.id

  // if isMetrics
  const isQueryRange = chart?.datasourceType == ChartDataSourceType.METRICS
  const limit = useTableLimit(chart?.type, chart?.config)
  const step = computeStep(chart, startTime, endTime)

  const queryRange = useQueryRange(
    isQueryRange && enabled ? projectId : null,
    chart?.queries || [],
    chart?.formulas || [],
    startTime,
    endTime,
    tz,
    templateValues,
    refreshInterval,
    limit,
    fromShare
  )

  // if isAnalytics
  const isQuerySegmentation = chart?.datasourceType == ChartDataSourceType.ANALYTICS
  const querySegmentation = useQuerySegmentation(
    isQuerySegmentation && enabled ? projectId : undefined,
    startTime,
    endTime,
    tz,
    step,
    chart?.segmentationQueries || [],
    chart?.formulas || [],
    templateValues,
    limit,
    fromShare
  )

  const isInsights = chart?.datasourceType == ChartDataSourceType.INSIGHTS
  const queryInsights = useQueryInsights(
    isInsights && enabled ? projectId : undefined,
    startTime,
    endTime,
    tz,
    step,
    chart?.insightsQueries || [],
    chart?.formulas || [],
    templateValues,
    limit,
    0,
    refreshInterval,
    fromShare,
    panel?.chart?.config?.timeRangeOverride?.compareTime,
    {
      noCache: false,
      cacheTtlSecs: Math.floor(toDayjs(endTime).diff(toDayjs(startTime), 'seconds') / 2),
      cacheRefreshTtlSecs: step ? Math.floor(durationToSeconds(step) / 2) : 0
    },
    true
  )

  const isRetention = chart?.datasourceType == ChartDataSourceType.RETENTION
  const queryRetention = useRetentionQuery(
    isRetention && enabled ? projectId : undefined,
    startTime,
    endTime,
    step || { value: 1, unit: 'd' },
    chart?.retentionQuery || {},
    refreshInterval,
    fromShare
  )

  const isSql = chart?.datasourceType == ChartDataSourceType.SQL
  const { sql, size } = useMemo(() => {
    try {
      return JSON.parse(chart?.sqlQuery || '{}')
    } catch (e) {
      console.error(e)
      return { sql: undefined, size: undefined }
    }
  }, [chart?.sqlQuery])
  const querySql = useSQLQuery(
    isSql && enabled ? projectId : undefined,
    sql,
    size,
    templateValues,
    fromShare,
    startTime,
    endTime,
    tz,
    Source.DASHBOARD,
    {
      noCache: false,
      cacheTtlSecs: Math.floor(toDayjs(endTime).diff(toDayjs(startTime), 'seconds') / 2),
      cacheRefreshTtlSecs: step ? Math.floor(durationToSeconds(step) / 2) : 0
    }
  )

  return useMemo(() => {
    const response: PanelQueryResponse = {
      loading: false,
      ready: false,
      isQueryRange,
      isQuerySegmentation,
      isInsights
    }
    if (isQueryRange) {
      Object.assign(response, queryRange)
      response.apiUrl = `/api/v1/metrics/${queryRange.payload.projectOwner}/${queryRange.payload.projectSlug}/query_range`
    } else if (isQuerySegmentation) {
      Object.assign(response, querySegmentation)
      response.apiUrl = `/api/v1/analytics/${querySegmentation.payload.projectOwner}/${querySegmentation.payload.projectSlug}/segmentation`
    } else if (isInsights) {
      Object.assign(response, queryInsights)
      response.apiUrl = `/api/v1/insights/${querySegmentation.payload.projectOwner}/${querySegmentation.payload.projectSlug}/query`
    } else if (isRetention) {
      Object.assign(response, queryRetention)
      response.apiUrl = `/api/v1/retention/${queryRetention.payload.projectOwner}/${queryRetention.payload.projectSlug}/query`
    } else if (isSql) {
      Object.assign(response, querySql)
      response.apiUrl = `/api/v1/analytics/${querySql.payload?.projectOwner}/${querySql.payload?.projectSlug}/sql/execute`
    }

    return response
  }, [
    isQueryRange,
    isQuerySegmentation,
    isInsights,
    queryRange,
    querySegmentation,
    queryInsights,
    isRetention,
    isSql,
    queryRetention,
    querySql
  ])
}

export function computeStep(chart: Chart | undefined, startTime: DateTimeValue, endTime: DateTimeValue) {
  switch (chart?.datasourceType) {
    case ChartDataSourceType.RETENTION:
      return { value: 1, unit: 'd' }
    case ChartDataSourceType.INSIGHTS: {
      const d = chart?.config?.timeRangeOverride?.timeRange?.interval
      if (!d) {
        return calculateStep(startTime, endTime)
      }
      return chart?.config?.timeRangeOverride?.timeRange?.interval
    }
    case ChartDataSourceType.ANALYTICS:
      return chart?.config?.timeRangeOverride?.timeRange?.interval
    case ChartDataSourceType.METRICS:
    default:
      return calculateStep(startTime, endTime)
  }
}
